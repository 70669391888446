var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.exportOptions.length > 0 && !_vm.hideButton ? _c('v-menu', {
    attrs: {
      "auto": "",
      "offset-y": "",
      "flat": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var onMenu = _ref.on;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var onTooltip = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": _vm.reset
                }
              }, Object.assign({}, onTooltip, onMenu)), [_c('v-icon', [_vm._v("mdi-file-send-outline")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToExternOption.tooltip")))])])];
      }
    }], null, false, 1704470340)
  }, [_c('v-list', {
    attrs: {
      "elevation": "0"
    }
  }, _vm._l(_vm.exportOptions, function (item, index) {
    return _c('v-list-item', {
      key: index
    }, [_c('v-btn', {
      staticStyle: {
        "width": "100%",
        "float": "left"
      },
      attrs: {
        "plain": ""
      },
      on: {
        "click": function click($event) {
          return _vm.openSendDialog(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
  }), 1)], 1) : _vm._e(), !_vm.isSetupCheckLoaded() ? _c('v-dialog', {
    attrs: {
      "width": "600"
    },
    on: {
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.goToSetup.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)) { return null; }
        return _vm.closeDialog.apply(null, arguments);
      }]
    },
    model: {
      value: _vm.isDialogActive,
      callback: function callback($$v) {
        _vm.isDialogActive = $$v;
      },
      expression: "isDialogActive"
    }
  }, [_c('v-card', [!_vm.isSetupCheckLoaded() ? _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "card-heading, divider, list-item-two-line,list-item-two-line"
    }
  }) : _vm._e(), !_vm.isSetup() ? _c('div', [_c('v-card-title', {
    attrs: {
      "primary-title": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToExternOption.title")) + " ")]), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToExternOption.info")) + " ")]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToExternOption.cancel")))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.goToSetup
    }
  }, [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToExternOption.setup")))])], 1)], 1) : _vm._e()], 1)], 1) : _c('span', [_vm.isKsrSetup ? _c('send-to-ksr-dialog', {
    ref: "ksrDialog",
    attrs: {
      "thirdPartySystem": _vm.thirdPartySystemKsr
    }
  }) : _vm._e(), _vm.isDaSetup ? _c('send-to-da-dialog', {
    ref: "daDialog",
    attrs: {
      "thirdPartySystem": _vm.thirdPartySystemDa
    }
  }) : _vm._e(), _vm.isPdrSetup ? _c('send-to-pdr-dialog', {
    ref: "pdrDialog"
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }