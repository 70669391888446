var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-report-step', {
    attrs: {
      "isDone": _vm.isDone,
      "title": _vm.header.title,
      "description": _vm.header.description,
      "image": _vm.header.image,
      "debug": _vm.header.debug
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-radio-group', {
    attrs: {
      "mandatory": ""
    },
    model: {
      value: _vm.liabilityDamage,
      callback: function callback($$v) {
        _vm.liabilityDamage = $$v;
      },
      expression: "liabilityDamage"
    }
  }, [_c('v-card', {
    staticClass: "pa-2 pt-4 my-2",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-radio', {
    attrs: {
      "value": _vm.CoverageEnum.SELF_PAYER
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('strong', [_vm._v(_vm._s(_vm.$t("report.liabilityDamage.cards.selfPayer.title")))])];
      },
      proxy: true
    }])
  }), _c('v-card-text', {
    staticClass: "ml-4"
  }, [_vm._v(_vm._s(_vm.$t("report.liabilityDamage.cards.selfPayer.description")))])], 1), _c('v-card', {
    staticClass: "pa-2 pt-4 my-2",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-radio', {
    attrs: {
      "value": _vm.CoverageEnum.INSURANCE
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo('#scrollmarker');
      }
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('strong', [_vm._v(_vm._s(_vm.$t("report.liabilityDamage.cards.insurance.title")))])];
      },
      proxy: true
    }])
  }), _c('v-card-text', {
    staticClass: "ml-4"
  }, [_vm._v(_vm._s(_vm.$t("report.liabilityDamage.cards.insurance.description")))])], 1), _c('v-card', {
    staticClass: "pa-2 pt-4 my-2",
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-radio', {
    attrs: {
      "value": _vm.CoverageEnum.INSURANCE_OPPONENT
    },
    on: {
      "click": function click($event) {
        return _vm.$vuetify.goTo('#scrollmarker');
      }
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', [_c('strong', [_vm._v(_vm._s(_vm.$t("report.liabilityDamage.cards.insuranceOpponent.title")))])])];
      },
      proxy: true
    }])
  }), _c('v-card-text', {
    staticClass: "ml-4"
  }, [_vm._v(_vm._s(_vm.$t("report.liabilityDamage.cards.insuranceOpponent.description")))])], 1)], 1)], 1), _c('span', {
    attrs: {
      "id": "scrollmarker"
    }
  }), _vm.liabilityDamage == _vm.CoverageEnum.INSURANCE_OPPONENT ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("report.liabilityDamage.liable.title")))]), _c('witness-form', {
    attrs: {
      "role": _vm.ReportRoleEnum.RESPONSIBLE,
      "show-insurance": true,
      "show-injury": false,
      "required": false
    },
    on: {
      "valid": function valid($e) {
        return _vm.isOpponentValid = $e;
      }
    },
    model: {
      value: _vm.witness,
      callback: function callback($$v) {
        _vm.witness = $$v;
      },
      expression: "witness"
    }
  })], 1) : _vm._e(), _vm.liabilityDamage == _vm.CoverageEnum.INSURANCE ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t("report.liabilityDamage.cards.insurance.title")))]), _c('insurance-form', {
    attrs: {
      "types": _vm.insuranceTypes,
      "required": true
    },
    on: {
      "valid": function valid($e) {
        return _vm.isInsuranceValid = $e;
      }
    },
    model: {
      value: _vm.insurance,
      callback: function callback($$v) {
        _vm.insurance = $$v;
      },
      expression: "insurance"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }