var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "100%"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("components.partner.PartnerReportDetail.SendToDaDialog.title")) + " ")]), _c('v-row', [_c('v-col', [_c('v-card-subtitle', [_vm._v(" An Schadensmeldung anhängen ")]), _vm._l(_vm.tpsses, function (tps) {
    return _c('v-list-item', {
      key: 'tps' + tps.externalId
    }, [_c('v-list-item-avatar', [_c('v-icon', [_vm._v("mdi-calendar")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(tps.externalId) + " ")])], 1)], 1);
  })], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }