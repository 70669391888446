var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('timeline-card', {
    attrs: {
      "title": _vm.$t('report.liabilityDamage.title'),
      "divider": true
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_vm._v(_vm._s(_vm.$t(this.text)))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }