var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "touch",
      rawName: "v-touch",
      value: _vm.gestures,
      expression: "gestures"
    }],
    style: "height: ".concat(_vm.height, ";"),
    attrs: {
      "height": ""
    }
  }, [_vm.isLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "image"
    }
  }) : _vm.vertical && _vm.images.length ? _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticStyle: {
      "background-color": "rgb(245, 245, 245)"
    },
    attrs: {
      "cols": "2"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "block": "",
      "elevation": 0,
      "height": 16
    },
    on: {
      "click": _vm.openPreviousPage
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-up")])], 1), _c('v-spacer')], 1), _c('div', {
    style: "height:calc(".concat(_vm.height, " - 32px); overflow-y: auto;")
  }, _vm._l(_vm.images, function (image, index) {
    return _c('v-card', {
      key: 'pdfimage' + index,
      class: _vm.currentPage !== index ? 'ma-1' : 'ma-2',
      attrs: {
        "outlined": _vm.currentPage === index,
        "flat": ""
      }
    }, [_c('v-img', {
      attrs: {
        "id": _vm.getId(index),
        "src": image,
        "contain": ""
      },
      on: {
        "click": function click($event) {
          _vm.currentPage = index;
        }
      }
    })], 1);
  }), 1), _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "block": "",
      "elevation": 0,
      "height": 16
    },
    on: {
      "click": _vm.openNextPage
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-down")])], 1), _c('v-spacer')], 1)]), _c('v-col', {
    style: "height: ".concat(_vm.height, ";"),
    attrs: {
      "cols": "10"
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('image-position-selector', {
    key: 'selector' + _vm.currentPage,
    ref: "selector",
    attrs: {
      "resizeTimeout": 1,
      "currentPageNumber": _vm.currentPage,
      "supressKeyboardActions": true,
      "src": _vm.images[_vm.currentPage],
      "isSigning": true,
      "enableMagnify": true,
      "hideTokenFields": true
    }
  })], 1)], 1)], 1) : _vm.horizontal && _vm.images.length ? _c('v-row', {
    staticClass: "px-2",
    style: "height: ".concat(_vm.height, "; display: flex;"),
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    style: "height: calc(".concat(_vm.height, " - ").concat(_vm.images.length > 1 ? 150 : 0, "px);"),
    attrs: {
      "cols": "12"
    }
  }, [_c('v-slide-x-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('image-position-selector', {
    key: 'selector' + _vm.currentPage,
    ref: "selector",
    attrs: {
      "resizeTimeout": 1,
      "currentPageNumber": _vm.currentPage,
      "supressKeyboardActions": true,
      "src": _vm.images[_vm.currentPage],
      "isSigning": true,
      "enableMagnify": true,
      "hideTokenFields": true
    }
  })], 1)], 1), _vm.images.length > 1 ? _c('v-col', {
    staticClass: "px-1",
    staticStyle: {
      "height": "150px",
      "background-color": "rgb(245, 245, 245)"
    },
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-small": "",
      "elevation": 0,
      "height": 150
    },
    on: {
      "click": _vm.openPreviousPage
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('div', {
    staticClass: "my-auto",
    staticStyle: {
      "overflow-x": "auto",
      "display": "flex",
      "flex-direction": "row"
    }
  }, _vm._l(_vm.images, function (image, index) {
    return _c('v-card', {
      key: 'pdfimage' + index,
      class: _vm.currentPage !== index ? 'ma-1' : 'ma-2',
      attrs: {
        "outlined": _vm.currentPage === index,
        "flat": ""
      }
    }, [_c('v-img', {
      staticStyle: {
        "max-width": "100px"
      },
      attrs: {
        "id": _vm.getId(index),
        "src": image,
        "contain": ""
      },
      on: {
        "click": function click($event) {
          _vm.currentPage = index;
        }
      }
    })], 1);
  }), 1), _c('v-btn', {
    attrs: {
      "x-small": "",
      "elevation": 0,
      "height": 150
    },
    on: {
      "click": _vm.openNextPage
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1)]) : _vm._e()], 1) : _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    style: "height: ".concat(_vm.height, ";"),
    attrs: {
      "cols": "12"
    }
  }, [_c('v-slide-y-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('image-position-selector', {
    key: 'selector' + _vm.currentPage,
    ref: "selector",
    attrs: {
      "resizeTimeout": 1,
      "currentPageNumber": _vm.currentPage,
      "supressKeyboardActions": true,
      "src": _vm.images[_vm.currentPage],
      "isSigning": true,
      "enableMagnify": true,
      "hideTokenFields": true
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }